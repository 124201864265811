import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

const HeaderTwo = () => {
  const [active, setActive] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='mean-expand-class'>+</span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".mean-expand-class");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
    window.onscroll = () => {
      if (window.pageYOffset < 150) {
        setScroll(false);
      } else if (window.pageYOffset > 150) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
  }, []);

  const mobileMenu = () => {
    setActive(!active);
  };
  return (
    <header className="nav-header header-layout2">
      <div className="header-top">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
            <div className="col-auto d-none d-lg-block">
              <div className="header-logo">
                <a href="/">
                  <img src="assets/img/logo.png" alt="Services ACIB LLC" />
                </a>
              </div>
            </div>
            <div className="col-auto">
              <div className="header-grid-info">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="header-grid-info-details">
                      <p>Phone Number</p>
                      <h6>
                        <a href="tel:8134016092">813 401 6092</a>
                      </h6>
                    </div>
                  </li>

                  <li>
                    <div className="icon">
                      <i className="fas fa-clock" />
                    </div>
                    <div className="header-grid-info-details">
                      <p>Mon - Sat</p>
                      <h6>9 am - 8 pm</h6>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="header-grid-info-details">
                      <p>We work in Tampa</p>
                      <h6>and its surroundings.</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`sticky-wrapper ${scroll && "sticky"}`}>
        {/* Main Menu Area */}
        <div className="menu-area">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto header-navbar-logo">
                <div className="header-logo">
                  <a href="/">
                    <img
                      src="assets/img/logo-white.png"
                      alt="Services ACIB LLC"
                    />
                  </a>
                </div>
              </div>
              <div className="col-auto">
                <nav className="main-menu d-none d-lg-inline-block">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to="about"
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to="services"
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to="gallery"
                      >
                        Gallery
                      </Link>
                    </li>

                    <li>
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to="contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
                <div className="navbar-right d-inline-flex d-lg-none">
                  <button
                    type="button"
                    className="menu-toggle icon-btn"
                    onClick={mobileMenu}
                  >
                    <i className="fas fa-bars" />
                  </button>
                </div>
              </div>
              <div className="col-auto d-xl-block d-none">
                <div className="social-links">
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    href="https://www.twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`mobile-menu-wrapper  ${active && "body-visible"}`}>
          <div className="mobile-menu-area">
            <div className="mobile-logo">
              <a href="/">
                <img src="assets/img/logo.png" alt="Services ACIB LLC" />
              </a>
              <button className="menu-toggle" onClick={mobileMenu}>
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="mobile-menu">
              <ul id="offcanvas-navigation">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    to="about"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    to="services"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    to="gallery"
                  >
                    Gallery
                  </Link>
                </li>

                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    to="contact"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
