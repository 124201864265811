import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade, Autoplay } from "swiper";
import { Link } from "react-scroll";
const HeroThree = () => {
  return (





    <div className="hero-wrapper hero-3">
      <div className="hero-3-slider global-carousel">
        <Swiper
          loop={true}
          modules={[FreeMode, Navigation, Thumbs, EffectFade, Autoplay]}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          effect="fade"
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_1_1.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3"
                     style={ {  padding: "100px 0 215px" } }>
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Services ACIB LLC"
                          />
                          Welcome at Services ACIB LLC
                        </span>{" "}
                      </div>
                      <h1
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Reveal Your Ride's Radiance
                      </h1>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
                       Unlock the brilliance of your vehicle with our meticulous detailing.
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          to="about"
                          className="btn"
                        >
                          Contact
                        </Link>
                        <div className="call-media-wrap">
                          <div className="icon">
                            <img
                              src="assets/img/icon/phone-1.svg"
                              alt="Services ACIB LLC"
                            />
                          </div>
                          <div className="media-body">
                            <h6 className="title text-white">Call us:</h6>
                            <h4 className="link">
                              <a className="text-white" href="tel:12345678">
                                12345678
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_1_2.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3" style={ {  padding: "100px 0 215px" } }>
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Services ACIB LLC"
                          />
                          Welcome at Services ACIB LLC
                        </span>{" "}
                      </div>
                      <h1
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Revitalize Your Vehicle's Interior
                      </h1>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
Step into luxury with our deep interior cleaning services.{" "}
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          to="about"
                          className="btn"
                        >
                          Contact
                        </Link>
                        <div className="call-media-wrap">
                          <div className="icon">
                            <img
                              src="assets/img/icon/phone-1.svg"
                              alt="Services ACIB LLC"
                            />
                          </div>
                          <div className="media-body">
                            <h6 className="title text-white">Call us:</h6>
                            <h4 className="link">
                              <a className="text-white" href="tel:12345678">
                                12345678
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="hero-slide"
              style={{
                backgroundImage: "url(assets/img/hero/hero_bg_1_3.png)",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="hero-style3" style={ {  padding: "100px 0 215px" } }>
                      <div
                        className="hero-subtitle text-white"
                        data-ani="slideinup"
                        data-ani-delay="0s"
                      >
                        <span>
                          <img
                            src="assets/img/hero/hero_shape_3.png"
                            alt="Services ACIB LLC"
                          />
                          Welcome at Services ACIB LLC
                        </span>{" "}
                      </div>
                      <h1
                        className="hero-title text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.1s"
                      >
                        Protect Your Investment with Premium Care"
                      </h1>
                      <p
                        className="hero-text text-white"
                        data-ani="slideinup"
                        data-ani-delay="0.2s"
                      >
                        Preserve your vehicle's value with our high-quality detailing treatments.{" "}
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinup"
                        data-ani-delay="0.3s"
                      >
                        <Link
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          to="about"
                          className="btn"
                        >
                          Contact
                        </Link>
                        <div className="call-media-wrap">
                          <div className="icon">
                            <img
                              src="assets/img/icon/phone-1.svg"
                              alt="Services ACIB LLC"
                            />
                          </div>
                          <div className="media-body">
                            <h6 className="title text-white">Call us:</h6>
                            <h4 className="link">
                              <a className="text-white" href="tel:12345678">
                                12345678
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HeroThree;
