import React from "react";

const WhyChooseUsOne = () => {
  return (
    <div className="wcu-area-1 space-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">This is how we work</span>
              <h2 className="sec-title">
                We have methods that guarantee excellence{" "}
                <img
                  className="title-bg-shape shape-center"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="Fixturbo"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-40 gx-60 align-items-center">
          <div className="col-xl-4 order-xl-2">
            <div className="wcu-thumb-1">
              <img src="assets/img/normal/wcu-thumb-1-1.png" alt="Fixturbo" />
            </div>
          </div>
          <div className="col-xl-4 col-md-6 order-xl-1">
            <div className="wcu-card-wrap right-wrap">
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-1.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Assessment</h4>
                  <p className="wcu-card-text">
                    We begin by conducting a thorough assessment of your
                    vehicle's condition, identifying specific cleaning and
                    detailing needs.{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-2.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Preparation</h4>
                  <p className="wcu-card-text">
                    Preparing your vehicle for the detailing process is crucial.
                    We carefully wash and prep the exterior, ensuring optimal
                    results.{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-3.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Detailing</h4>
                  <p className="wcu-card-text">
                    Our skilled technicians perform a meticulous detailing
                    process, focusing on both the exterior and interior to
                    achieve showroom-quality results.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 order-xl-3">
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-4.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Specialized Treatments</h4>
                <p className="wcu-card-text">
                  We offer a range of specialized treatments, including paint
                  protection, headlight restoration, and leather care, tailored
                  to your vehicle's unique needs.{" "}
                </p>
              </div>
            </div>
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-5.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Quality Inspection</h4>
                <p className="wcu-card-text">
                  Before handing back your vehicle, we conduct a rigorous
                  quality inspection, ensuring that every detail meets our high
                  standards of excellence.{" "}
                </p>
              </div>
            </div>
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-6.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Customer Satisfaction</h4>
                <p className="wcu-card-text">
                  Your satisfaction is our priority. We strive to exceed your
                  expectations, providing exceptional service and leaving your
                  vehicle looking and feeling its best.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUsOne;
