import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const AboutTwo = () => {
  return (
    <div className="space-top" id="about">
      <div className="container">
        <div className="row">
          <div className="col-xxl-7 col-xl-6">
            <div className="about-thumb2 mb-40 mb-xl-0">
              <div className="about-img-1">
                <img
                  src="assets/img/normal/about_2-1.png"
                  alt="Services ACIB LLC"
                />
              </div>
              <div className="about-img-2">
                <img
                  src="assets/img/normal/about_2-2.png"
                  alt="Services ACIB LLC"
                />
              </div>
              <div className="about-counter-wrap jump-reverse">
                <img
                  src="assets/img/icon/about_icon2-1.svg"
                  alt="Services ACIB LLC"
                />
                <h3 className="about-counter">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter-number">
                          <CountUp delay={0} start={0} end={5} />
                          k+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h3>
                <h4 className="about-counter-text">Customer </h4>
              </div>
              <div className="about-year-wrap2 movingX">
                <div className="about-year-grid-wrap">
                  <div className="icon">
                    <img
                      src="assets/img/icon/about_icon2-2.png"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h3 className="about-counter">
                    <span className="counter-number">10</span>+
                  </h3>
                </div>
                <h4 className="about-year-text">Years </h4>
              </div>
            </div>
          </div>
          <div className="col-xxl-5 col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area mb-30">
                <span className="sub-title">About Us</span>
                <h2 className="sec-title">
                  Services ACIB LLC{" "}
                  <img
                    className="title-bg-shape shape-center"
                    src="assets/img/bg/title-bg-shape.png"
                    alt="Services ACIB LLC"
                  />
                </h2>
                <p className="sec-text">
                  At Services ACIB LLC, we're passionate about providing the
                  highest quality vehicle detailing services in Kissimmee, FL.
                  With a commitment to excellence and a keen attention to
                  detail, our experienced team ensures that every vehicle leaves
                  our facility looking its absolute best. Whether you're seeking
                  a thorough exterior wash, a meticulous interior cleaning, or
                  specialized treatments to protect and enhance your vehicle,
                  you can trust us to deliver outstanding results every time.{" "}
                </p>
              </div>
              <div className="about-feature-wrap style-shadow">
                <div className="icon">
                  <img
                    src="assets/img/icon/about_icon2-3.svg"
                    alt="Services ACIB LLC"
                  />
                </div>
                <div className="about-feature-wrap-details">
                  <h5 className="about-feature-title">Mission</h5>
                  <p className="about-feature-text">
                    Our mission at Services ACIB LLC is to exceed our customers'
                    expectations by delivering unparalleled vehicle detailing
                    services with professionalism, integrity, and attention to
                    detail. We strive to provide a convenient and enjoyable
                    experience for every client, ensuring their complete
                    satisfaction and earning their trust as their go-to
                    destination for automotive care."{" "}
                  </p>
                </div>
              </div>
              <div className="about-feature-wrap style-shadow">
                <div className="icon">
                  <img
                    src="assets/img/icon/about_icon2-4.svg"
                    alt="Services ACIB LLC"
                  />
                </div>
                <div className="about-feature-wrap-details">
                  <h5 className="about-feature-title">Contact us!</h5>
                  <p className="about-feature-text">
                    Ready to give your vehicle the VIP treatment it deserves?
                    Contact us today to schedule your appointment or inquire
                    about our services. Our friendly team is here to assist you
                    and answer any questions you may have. Experience the
                    difference with Services ACIB LLC.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
