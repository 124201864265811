import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade } from "swiper";

const ClientAreaThree = () => {
  return (
    <div
      className="client-bg-area-2"
      style={{ backgroundImage: "url(assets/img/bg/client-bg2-1.png)" }}
    >
      {/*==============================
  Testimonial Area  
  ==============================*/}
      <div className="testimonial-area-2 overflow-hidden">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-7">
              <div className="testiomonial-wrap-2 bg-title">
                <div className="title-area">
                  <span className="sub-title">Clients</span>
                  <h2 className="sec-title text-white">Opinions</h2>
                </div>
                <div className="quote-icon">
                  <img src="assets/img/icon/quote2-1.svg" alt="Services ACIB" />
                </div>
                <div className="row global-carousel testi-slider-2">
                  <Swiper
                    loop={true}
                    navigation={{
                      nextEl: ".next",
                      prevEl: ".prev",
                    }}
                    spaceBetween={20}
                    slidesPerGroup={1}
                    speed={1000}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 6000 }}
                    modules={[FreeMode, Navigation, Thumbs, EffectFade]}
                    className="mySwiper"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      992: {
                        slidesPerView: 1,
                      },
                      1200: {
                        slidesPerView: 1,
                      },
                      1400: {
                        slidesPerView: 1,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div>
                        <div className="testi-card style2">
                          <div className="testi-card_content">
                            <div className="testi-card-profile">
                              <div className="testi-profile_thumb">
                                <img
                                  src="assets/img/testimonial/testimonial-2-1.png"
                                  alt="Services ACIB"
                                />
                              </div>
                              <div className="testi-card-profile-details">
                                <h4 className="testi-profile-title">
                                  Anderson Lugo{" "}
                                </h4>
                                <span className="testi-profile-desig">
                                  Ingeniero de Software
                                </span>
                              </div>
                            </div>
                            <p className="testi-card_text">
                              ¡Increíble trabajo! Mi auto nunca ha estado tan
                              limpio y reluciente. Definitivamente volveré a
                              Services ACIB LLC para mi próximo lavado.
                              ¡Altamente recomendado!
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <div className="testi-card style2">
                          <div className="testi-card_content">
                            <div className="testi-card-profile">
                              <div className="testi-profile_thumb">
                                <img
                                  src="assets/img/testimonial/testimonial-2-1.png"
                                  alt="Services ACIB"
                                />
                              </div>
                              <div className="testi-card-profile-details">
                                <h4 className="testi-profile-title">
                                  Juan Pérez{" "}
                                </h4>
                                <span className="testi-profile-desig">
                                  Contador
                                </span>
                              </div>
                            </div>
                            <p className="testi-card_text">
                              Quedé impresionado con el nivel de atención al
                              detalle de este lugar. Su equipo hizo un trabajo
                              excepcional tanto en el exterior como en el
                              interior de mi vehículo. ¡Gracias por el excelente
                              servicio!
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <div className="testi-card style2">
                          <div className="testi-card_content">
                            <div className="testi-card-profile">
                              <div className="testi-profile_thumb">
                                <img
                                  src="assets/img/testimonial/testimonial-2-1.png"
                                  alt="Services ACIB"
                                />
                              </div>
                              <div className="testi-card-profile-details">
                                <h4 className="testi-profile-title">
                                  María Gutiérrez{" "}
                                </h4>
                                <span className="testi-profile-desig">---</span>
                              </div>
                            </div>
                            <p className="testi-card_text">
                              ¡No puedo creer la diferencia que hicieron en mi
                              auto! Parece nuevo de nuevo. El personal fue
                              amable y profesional, y el resultado final superó
                              mis expectativas. Definitivamente los recomendaré
                              a mis amigos y familiares.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div>
                        <div className="testi-card style2">
                          <div className="testi-card_content">
                            <div className="testi-card-profile">
                              <div className="testi-profile_thumb">
                                <img
                                  src="assets/img/testimonial/testimonial-2-1.png"
                                  alt="Services ACIB"
                                />
                              </div>
                              <div className="testi-card-profile-details">
                                <h4 className="testi-profile-title">
                                  Carlos Martínez{" "}
                                </h4>
                                <span className="testi-profile-desig">
                                  Abogado
                                </span>
                              </div>
                            </div>
                            <p className="testi-card_text">
                              Excelente servicio de principio a fin. Programé
                              una cita fácilmente y el equipo de Services ACIB
                              LLC hizo un trabajo excepcional en mi vehículo.
                              ¡No dudaré en volver y obtener otro lavado
                              detallado en el futuro!
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="icon-box">
                  <button
                    data-slick-prev=".testi-slider-2"
                    className="slick-arrow style3 default next"
                  >
                    <i className="fas fa-arrow-left" />
                  </button>
                  <button
                    data-slick-next=".testi-slider-2"
                    className="slick-arrow style3 default prev"
                  >
                    <i className="fas fa-arrow-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-thumb-2">
          <img src="assets/img/testimonial/2.png" alt="Services ACIB" />
        </div>
      </div>
    </div>
  );
};

export default ClientAreaThree;
