import { BrowserRouter, Route, Routes } from "react-router-dom";

import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";

import HomePageTwo from "./pages/HomePageTwo";


function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ScrollToTop smooth color="#4E7FA3" />
      <Routes>
       
        <Route exact path="/" element={<HomePageTwo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
