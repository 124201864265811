import React from "react";
import { Link } from "react-scroll";

const ServiceAreaTwo = () => {
  return (
    <div className="service-area-2 space overflow-hidden" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="title-area text-center">
              <span className="sub-title">Services</span>
              <h2 className="sec-title">
                Our comprehensive vehicle detailing services ensure your ride
                shines inside and out. Trust our expert team to elevate your
                driving experience.{" "}
                <img
                  className="title-bg-shape shape-center"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="Services ACIB LLC"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-1.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-1.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Exterior Detailing
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    Restore your vehicle's shine with our thorough exterior
                    cleaning service.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-2.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-2.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Interior Detailing
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    Experience a fresh, clean cabin with our meticulous interior
                    detailing.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-3.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-3.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Paint Protection
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    Keep your paint looking pristine with our premium protective
                    coatings.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-4.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-4.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Headlight Restoration
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    Enhance visibility and aesthetics with our professional
                    headlight restoration.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-5.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-5.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Leather Care
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    Revitalize your leather upholstery with our specialized
                    conditioning treatments.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-6.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-6.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Odor Removal{" "}
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    Banish unwanted odors and enjoy a fresh-smelling interior
                    once again.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/service/service-2-6.png)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img
                      src="assets/img/icon/service-icon_1-6.svg"
                      alt="Services ACIB LLC"
                    />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Pressure Washing and Soft Washing{" "}
                    </Link>
                  </h4>
                  <p className="service-card_text">
                    In addition to our vehicle detailing services, we also offer
                    pressure washing and soft washing for exterior walls and
                    floors. Using Hydro jet technology, we effectively remove
                    dirt, grime, and stains, restoring the beauty of your
                    property's surfaces with precision and care. Whether you
                    need your driveway, patio, or building facade cleaned, count
                    on us to deliver exceptional results that enhance the
                    appearance of your property.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaTwo;
