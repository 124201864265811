import React from "react";
import { Link } from "react-router-dom";

const FooterAreaTwo = () => {
  return (
    <footer
      className="footer-wrapper footer-layout2"
      style={{ backgroundImage: "url(assets/img/bg/footer-bg2-1.png)" }}
      id="contact"
    >
      <div className="container">
        <div className="footer-top-2">
          <div className="footer-logo">
            <Link to="/">
              <img src="assets/img/logo-white.png" alt="Services ACIB" />
            </Link>
          </div>
          <h4 className="footer-top-title text-white">
            We are experts in cleaning your vehicle
          </h4>
        </div>
        <div className="widget-area">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-4">
              <div className="widget footer-widget widget-about">
                <h3 className="widget_title">About us</h3>
                <p className="footer-text mb-30">
                  At Services ACIB LLC, we're passionate about providing the
                  highest quality vehicle detailing services in Kissimmee, FL.
                  With a commitment to excellence and a keen attention to
                  detail, our experienced team ensures that every vehicle leaves
                  our facility looking its absolute best.
                </p>
                <div className="social-btn style3">
                  <Link to="https://www.instagram.com/" tabIndex={-1}>
                    <i className="fab fa-instagram" />
                  </Link>
                  <Link to="https://linkedin.com/" tabIndex={-1}>
                    <i className="fab fa-linkedin-in" />
                  </Link>
                  <Link to="https://twitter.com/" tabIndex={-1}>
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link to="https://facebook.com/" tabIndex={-1}>
                    <i className="fab fa-facebook-f" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Services</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      {" "}
                      <Link to="tel:123455">Exterior Detailing</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="tel:123455">Interior Detailing</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="tel:123455">Paint Protection</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="tel:123455">Headlight Restoration</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="tel:123455">Leather Care</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget">
                <h3 className="widget_title">Contact us</h3>
                <div className="widget-contact2">
                  <div className="widget-contact-grid">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="contact-grid-details">
                      <p>Address</p>
                      <h6>
                        Web work in Tampa and its surroundings.
                        <p />
                      </h6>
                    </div>
                  </div>
                  <div className="widget-contact-grid">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="contact-grid-details">
                      <p>Phone Number</p>
                      <h6>
                        <Link to="tel:8134016092">813 401 6092</Link>
                        <p />
                      </h6>
                    </div>
                  </div>
                  <div className="widget-contact-grid">
                    <div className="icon">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="contact-grid-details">
                      <p>Email Address</p>
                      <h6>
                        <Link to="info@servicesacib.com">
                          info@servicesacib.com
                        </Link>
                        <p />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row gy-3 justify-content-md-between justify-content-center">
            <div className="col-auto align-self-center">
              <p className="copyright-text text-center">
                © <Link to="#">Services ACIB</Link> 2024 | All Rights Reserved
              </p>
            </div>
            <div className="col-auto">
              <div className="footer-links">
                <Link to="contact">Tarms &amp; Condition</Link>
                <Link to="contact">Privacy Policy</Link>
                <Link to="contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterAreaTwo;
