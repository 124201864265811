import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade } from "swiper";
const PortfolioTwo = () => {
  return (
    <div className="portfolio-area-1 space overflow-hidden" id="gallery">
      <div className="container">
        <div className="row justify-content-between align-items-end">
          <div className="col-xl-12 col-lg-12">
            <div className="title-area">
              <span className="sub-title">Gallery</span>
              <h2 className="sec-title">
              Explore our gallery and witness the transformative results of our meticulous vehicle detailing services. Each image captures the impeccable craftsmanship and attention to detail that define Services ACIB LLC. From gleaming exteriors to spotless interiors, our gallery showcases the stunning outcomes that await your vehicle. Let these visuals inspire you to trust us with your automotive care needs.{" "}
                <img
                  className="title-bg-shape"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="Services ACIB"
                />
              </h2>
            </div>
          </div>
          <div className="col-sm-auto">
            <div className="title-area">
              <div className="icon-box">
                <button className="slick-arrow default portfolio-button-next">
                  <i className="fas fa-arrow-left" />
                </button>
                <button className="slick-arrow default portfolio-button-prev">
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row global-carousel gx-30 portfolio-slider1">
          <Swiper
            loop={true}
            navigation={{
              nextEl: ".portfolio-button-next",
              prevEl: ".portfolio-button-prev",
            }}
            spaceBetween={30}
            centeredSlides
            slidesPerView={1}
            slidesPerGroup={1}
            speed={2000}
            pagination={{ clickable: true }}
            autoplay={{ delay: 6000 }}
            className="mySwiper"
            modules={[FreeMode, Navigation, Thumbs, EffectFade]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              },
              1400: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img
                      src="assets/img/portfolio/2-1.png"
                      alt="Services ACIB"
                    />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                      Exterior Detailing
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Restore your vehicle's shine with our thorough exterior cleaning service.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img
                      src="assets/img/portfolio/2-2.png"
                      alt="Services ACIB"
                    />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                      Interior Detailing
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Experience a fresh, clean cabin with our meticulous interior detailing.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img
                      src="assets/img/portfolio/2-3.png"
                      alt="Services ACIB"
                    />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                      Paint Protection
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Keep your paint looking pristine with our premium protective coatings.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img
                      src="assets/img/portfolio/2-4.png"
                      alt="Services ACIB"
                    />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                      Headlight Restoration
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Enhance visibility and aesthetics with our professional headlight restoration.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="portfolio-card style2">
                  <div className="portfolio-card-thumb">
                    <img
                      src="assets/img/portfolio/2-5.png"
                      alt="Services ACIB"
                    />
                  </div>
                  <div className="portfolio-card-details">
                    <div className="media-left">
                      <h4 className="portfolio-card-details_title">
                      Leather Care
                      </h4>
                      <span className="portfolio-card-details_subtitle">
                      Revitalize your leather upholstery with our specialized conditioning treatments.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTwo;
